import React, { Component } from "react";
import { Button } from "reactstrap";
import { clearItem } from "utils/tokenStore";
import { RiLogoutBoxLine } from "react-icons/ri";
import { connect } from "react-redux"
import { compose } from "redux"

import CustomIcon from "components/Icon";

import { toCapitalizeAll } from "utils/toCapitalize";
import { setUserProfile } from "reducers/profile";

class Userbox extends Component {
  render = () => {
    const { profile } = this.props.data.ProfileReducer;

    return (
      <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
        <CustomIcon antIcon="UserOutlined" style={{ fontSize: "18px" }} className="mr-2" />
        <span style={{fontSize: "15px", fontWeight: "500"}}>{toCapitalizeAll(profile?.username ?? '-')}</span>
      </div>
      // <div className="header-btn-lg pr-0 ml-0" style={{ paddingLeft: "1rem" }}>
      //   <div className="widget-content p-0">
      //     <div className="widget-content-wrapper">
      //       <div className="widget-content-left d-flex align-items-center">
      //         <Button
      //           className="btn-pill btn-shadow btn-shine"
      //           color="focus"
      //           onClick={() => {
      //             Promise.all([
      //               clearItem("EON_ERP_ACCESS_TOKEN"),
      //               this.props.setUserProfile({}),
      //             ]).then(() => {
      //               window.location.reload()
      //             })
      //           }}
      //         >
      //           <RiLogoutBoxLine
      //             style={{ width: 20, height: 20, marginRight: 10 }}
      //           />
      //           Logout
      //         </Button>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  };
}

export default compose(
  connect(null, { setUserProfile }),
)(Userbox);

import React, { lazy } from "react";

const UserManagement = lazy(() => import("./UserManagement"));
const Colour = lazy(() => import("./Colour"));
const Variant = lazy(() => import("./Variant"));
const Brand = lazy(() => import("./Brand"));
const BrandModel = lazy(() => import("./BrandModel"));
const Product = lazy(() => import("./Product"));
const StaffIDManagement = lazy(() => import("./StaffID"));
const BranchManagement = lazy(() => import("./BranchManagement"));
const FinancingBank = lazy(() => import("./FinancingBank"));
const Setting = lazy(() => import("../Setting"));
const DashboardHome = lazy(() => import("../Dashboard/Home"));
const LoanApplication = lazy(() => import("../Dashboard/Application"));

export const routes = [
  {
    path: "/user_management",
    exact: true,
    render: (props) => <UserManagement {...props} />
  },
  {
    path: "/colour",
    exact: true,
    render: (props) => <Colour {...props} />
  },
  {
    path: "/variant",
    exact: true,
    render: (props) => <Variant {...props} />
  },
  {
    path: "/brand",
    exact: true,
    render: (props) => <Brand {...props} />
  },
  {
    path: "/brand_model",
    exact: true,
    render: (props) => <BrandModel {...props} />
  },
  {
    path: "/product",
    exact: true,
    render: (props) => <Product {...props} />
  },
  {
    path: "/staff_id_management",
    exact: true,
    render: (props) => <StaffIDManagement {...props} />
  },
  {
    path: "/branch_management",
    exact: true,
    render: (props) => <BranchManagement {...props} />
  },
  {
    path: "/financing_bank",
    exact: true,
    render: (props) => <FinancingBank {...props} />
  },
  {
    path: "/setting",
    exact: true,
    render: (props) => <Setting {...props} />
  },
  {
    path: "/home",
    exact: true,
    render: (props) => <DashboardHome {...props} />,
  },
  {
    path: "/application",
    exact: true,
    render: (props) => <LoanApplication {...props} />,
  },
];
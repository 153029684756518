import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import _ from "lodash";

import { clearItem } from "utils/tokenStore"
import { requestError } from "utils/requestHandler";
import { setUserProfile } from "reducers/profile";
import { setEnableMobileMenu } from "reducers/ThemeOptions";
import { SuperAdminNav, BankAdminNav, AdminNav, AccountNav, SalesManagerNav, HeadOfSalesNav, SalesAgentNav } from "./dataMainNav";
import './index.scss'

class VerticalNavWrapper extends Component {
  state = { menus: [] };

  onToggleLogOut = () => Promise.all([
    clearItem("EON_ERP_ACCESS_TOKEN"),
    this.props.setUserProfile({}),
  ]).then(() => {
    window.location.reload()
  });

  componentDidMount() {
    if (this.props.data.ProfileReducer.profile.role === "super admin") {
      this.setState({ menus: SuperAdminNav });
    } else if (this.props.data.ProfileReducer.profile.role === "bank admin") {
      this.setState({ menus: BankAdminNav });
    } else if (this.props.data.ProfileReducer.profile.role === "admin") {
      this.setState({ menus: AdminNav });
    } else if (this.props.data.ProfileReducer.profile.role === "sales manager") {
      this.setState({ menus: SalesManagerNav });
    } else if (this.props.data.ProfileReducer.profile.role === "head of sales") {
      this.setState({ menus: HeadOfSalesNav });
    } else if (this.props.data.ProfileReducer.profile.role === "sales agent") {
      this.setState({ menus: SalesAgentNav });
    }
  }

  render = () => {
    return (
      <>
        <h2 className="app-sidebar__heading">NAVIGATION</h2>
        <MetisMenu
          content={this.state.menus}
          onSelected={() => 
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />

        <h2 className="app-sidebar__heading">MY ACCOUNT</h2>
        <MetisMenu
          content={AccountNav}
          onSelected={(e) => {
            if( e.target.innerText === "Logout" ) {
              this.onToggleLogOut()
            }
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenum,
  data: state,
});

export default connect(mapStateToProps, {
  setUserProfile,
  setEnableMobileMenu,
})(VerticalNavWrapper);


// Head Quarter
export const AdminNav = [
    {
      icon: "icon-home-3",
      label: "Home",
      to: "#/admin/home",
    },
    {
      icon: "icon-filecheck",
      label: "Application",
      to: "#/admin/application",
    },
    {
      icon: "icon-user-square",
      label: "User Management",
      to: "#/admin/user_management",
    },
    {
      icon: "icon-tag",
      label: "Brand",
      to: "#/admin/brand",
    },
    {
      icon: "icon-car",
      label: "Brand Model",
      to: "#/admin/brand_model",
    },
    {
      icon: "icon-passcode",
      label: "Variant",
      to: "#/admin/variant",
    },
    {
      icon: "icon-palette",
      label: "Colour",
      to: "#/admin/colour",
    },
    {
      icon: "icon-home",
      label: "Product",
      to: "#/admin/product",
    },
    {
      icon: "icon-user-square",
      label: "Staff Management",
      to: "#/admin/staff_id_management",
    },
    {
      icon: "icon-user-square",
      label: "Branch Management",
      to: "#/admin/branch_management",
    },
    {
      icon: "icon-home-3",
      label: "Financing Bank",
      to: "#/admin/financing_bank",
    }
]

//Sales Agent
export const SalesAgentNav = [
  {
    icon: "icon-home-3",
    label: "Home",
    to: "#/dashboard/home",
  },
  {
    icon: "icon-filecheck",
    label: "Application",
    to: "#/dashboard/application",
  }
]

// Sales Manager
export const SalesManagerNav  = [
  {
    icon: "icon-home-3",
    label: "Home",
    to: "#/dashboard/home",
  },
  {
    icon: "icon-filecheck",
    label: "Application",
    to: "#/dashboard/application",
  },
  {
    icon: "icon-user-square",
    label: "User Management",
    to: "#/dashboard/user_management",
  },
  {
    icon: "icon-user-square",
    label: "Staff Management",
    to: "#/dashboard/staff_id_management",
  }
]

// Head of Sales
export const HeadOfSalesNav = [
  {
    icon: "icon-home-3",
    label: "Home",
    to: "#/dashboard/home",
  },
  {
    icon: "icon-filecheck",
    label: "Application",
    to: "#/dashboard/application",
  },
  {
    icon: "icon-user-square",
    label: "User Management",
    to: "#/dashboard/user_management",
  },
  {
    icon: "icon-user-square",
    label: "Staff Management",
    to: "#/dashboard/staff_id_management",
  }
]

// Sales Agent
export const BankAdminNav = [
  {
    icon: "icon-home-3",
    label: "Home",
    to: "#/dashboard/home",
  },
  {
    icon: "icon-filecheck",
    label: "Application",
    to: "#/dashboard/application",
  }
]

export const SuperAdminNav = [
  {
    icon: "icon-user-square",
    label: "User Management",
    to: "#/admin/user_management",
  },
  {
    icon: "icon-tag",
    label: "Brand",
    to: "#/admin/brand",
  },
  {
    icon: "icon-car",
    label: "Brand Model",
    to: "#/admin/brand_model",
  },
  {
    icon: "icon-passcode",
    label: "Variant",
    to: "#/admin/variant",
  },
  {
    icon: "icon-palette",
    label: "Colour",
    to: "#/admin/colour",
  },
  {
    icon: "icon-home",
    label: "Product",
    to: "#/admin/product",
  },
  {
    icon: "icon-user-square",
    label: "Staff Management",
    to: "#/admin/staff_id_management",
  },
  {
    icon: "icon-user-square",
    label: "Branch Management",
    to: "#/admin/branch_management",
  },
  {
    icon: "icon-home-3",
    label: "Financing Bank",
    to: "#/admin/financing_bank",
  }
];

export const AccountNav = [
  {
    icon: "icon-settings",
    label: "Setting",
    to: "#/dashboard/setting",
  },
  {
    icon: "icon-log-out",
    label: "Logout",
    to: "#/login",
  },
];

export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL"
export const AJAX_CALL_SUCCESS = "AJAX_CALL_SUCCESS"
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR"

export const GET_PROFILE = "GET_PROFILE"
export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE"

export const SET_USER_TYPE = "SET_USER_TYPE"

export const GET_DICTIONARY_ROLE = "GET_DICTIONARY_ROLE"

export const SET_PATH = "SET_PATH"
export const SET_EMPLOYMENT = "SET_EMPLOYMENT"
import { GET_PROFILE } from "../actions/type";

const initialState = {
  profile: {
    role: "sales agent",
    phone_no: "182607168",
    email: "jimmy.cheok.c.m@gmail.com",
    username: "Cheok Chee Ming",
    created_at: "2023-12-06T08:52:25.506Z",
    updated_at: "2023-12-06T08:52:25.506Z",
  },
};

export const setUserProfile = payload => ({
  type: GET_PROFILE,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect } from "react"
import Loader from "react-loaders"

import CustomCard from "components/Card"
import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"

import { getItem } from "utils/tokenStore";
import EONLogo from 'assets/Images/eon-svg.svg'
import LoginHOC from "./actions"

import './index.scss'

const Login = props => {

  useEffect(() => {
    if (getItem("EON_ERP_ACCESS_TOKEN")) {
      props.data.ProfileReducer.profile.role === 'super admin'
        ? props.history.push("/admin")
        : props.history.push("/dashboard/home")
    }
  }, [])

  const onToggleLogin = () => {
    let errors = []
    if( props.email?.trim() === '' ){
      errors.push({ field: 'email', message: 'Email is required.' })
    }
    if( props.password?.trim() === '' ){
      errors.push({ field: 'password', message: 'Password is required.' })
    }
    props.onChangeLoginHOC('errors', errors)

    if( errors.length === 0 ){
      props.onClickLogin({ email: props.email, password: props.password, role: 'administrator' })
    }
  }
  
  return (
    <>
      <div className="eon-main-inner" style={{ minHeight: '100vh', paddingTop: 100 }}>
        <div className="eon-login-title">
          <img src={ EONLogo } alt="eon-logo" />
          <span>Login</span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          { props.onLoadLogin
            ? <Loader type="ball-clip-rotate" />
            : (
              <CustomCard
                mode={"default"}
                containerStyle={{ padding: "24px", gap: "12px", width: 420 }}>
                <span className="text-sm text-light-grey font-weight-light mb-2">Please fill up your information.</span>
                  <FormInput
                    type="text"
                    context="email"
                    value={props.email}
                    label="Email"
                    placeholder={"Email"}
                    errors={props.errors}
                    onKeyDown={(e) => e.keyCode === 13 && onToggleLogin()}
                    onChangeData={(val) => props.onChangeLoginHOC("email", val)}/>
                  <FormInput
                    type="password"
                    context="password"
                    value={props.password}
                    label="Password"
                    placeholder={"xxxxxxxxxxxxx"}
                    errors={props.errors}
                    onKeyDown={(e) => e.keyCode === 13 && onToggleLogin()}
                    onChangeData={(val) => props.onChangeLoginHOC("password", val)}/>
                  <CustomButton
                    buttonClass={"full-width-button mt-4"}
                    size={"lg"}
                    color={"black"}
                    onClick={() => onToggleLogin()}
                    children={"Login"}/>
                  { props.errorMessage && <span className="text-sm text-danger font-weight-light">{props.errorMessage}</span> }
                  <div className="d-flex justify-content-center align-items-center" style={{ gap: "100px" }}>
                    <CustomButton
                      size={"sm"}
                      color={"borderless"}
                      onClick={() => props.history.push('/reset-password-request')}
                      children={"Reset Password"}
                    />
                    {/* <div className="text-sm text-light-grey d-flex justify-content-center">
                      <span className="font-weight-light pr-1">New User?</span>
                      <span
                        className="font-weight-light"
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => props.history.push('/registration')}>
                        Sign up here.
                      </span>
                    </div> */}
                  </div>
              </CustomCard>
            )
          }
        </div>
      </div>
    </>
  )
}

export default LoginHOC(Login);
import React from "react";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";

import HeaderLogo from "./Logo";
import Userbox from "./Userbox";

import "./index.scss";
import BreadcrumbComponent from "components/Breadcrumb";

const Header = ({
  data,
  pathData,
  headerBackgroundColor,
  enableMobileMenuSmall,
  history,
}) => {
  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-header", headerBackgroundColor)}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />
        <div
          className={cx("app-header__content", {
            "header-mobile-open": enableMobileMenuSmall,
          })}>
          <div className="app-header-left">
            <BreadcrumbComponent pathData={pathData} />
          </div>
          <div className="app-header-right">
            <Userbox data={data} history={history} />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state,
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from "react"
import { Button } from "reactstrap"

import "./index.scss"

const CustomButton = ({
  color, //white, black, borderless
  size, //sm, lg
  onClick, //function
  children,
  buttonStyle,
  buttonClass,
  disabled,
  close,
}) => {
  return (
    <Button
      style={{ ...buttonStyle }}
      className={`custom-button custom-button__${color} ${buttonClass || ""}`}
      size={size || "sm"}
      onClick={onClick}
      disabled={disabled}
      close={close}
    >
      {children}
    </Button>
  )
}

export default CustomButton
import React, { useEffect } from "react"

import TopBar from "components/TopBar"
import Breadcrumb from "components/Breadcrumb"
import LoadingOverlay from "components/Loading"
import TACForm from "./TAC"
import RegisterForm from "./Form"

import RegistrationHOC from "./actions"

const AgentRegister = props => {

  useEffect(() => {
    props.setPath([
      { label: "Loan Application" },
      { label: "Create Account" },
    ])
  }, [])

  return (
    <>
      <TopBar />
      <Breadcrumb pathData={props.setPath} />
      <div className="eon-main-inner">
        {!props.showTACPage && (
          <>
            <div className="d-flex flex-column" style={{ padding: "32px 0px", textAlign: "center" }}>
              <span className="text-lg text-grey font-weight-heavy">Create an Account</span>
              <span className="text-sm text-light-grey font-weight-light">Choose your role and create an account.</span>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RegisterForm {...props } />
            </div>
          </>
        )}
        
        {props.showTACPage && (
          <div className="d-flex align-items-center justify-content-center" style={{ paddingTop: "50px"}}>
            <TACForm {...props} />
          </div>
        )}
        {props.onLoadRegistration && <LoadingOverlay />}
      </div>
    </>
    
  )
}

export default RegistrationHOC(AgentRegister);
import React from "react"
import * as AntdIcons from '@ant-design/icons'
import * as FeatherIcons from 'react-icons/fi'
const customIcons = require.context('assets/Icons', true, /\.svg$/)

const CustomIcon = props => {
  let {
    className,
    style,
    antIcon,
    featherIcon,
    custom
  } = props

  if( custom ){
    const iconMap = customIcons.keys().reduce((images, path) => {
      const key = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'))
      images[key] = customIcons(path).default
      return images
    }, {})
    
    return(
      <img src={ iconMap[ custom ]} className={ className || "" } style={ style }/>
    )
  }

  if( antIcon ){
    let Component = AntdIcons[ antIcon ]
    return(
      <Component className={ className } style={ style }/>
    )
  }

  if( featherIcon ){
    let Component = FeatherIcons[ featherIcon ]
    return(
      <Component className={ className } style={ style }/>
    )
  }

  return (
    <i style={ style } className={ className || "" }/>
  )
}

export default CustomIcon
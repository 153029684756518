import { SET_EMPLOYMENT } from "../actions/type";

const initialState = {
  employment: []
};

export const setEmployment = payload => ({
  type: SET_EMPLOYMENT,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYMENT:
      return {
        ...state,
        employment: action.payload,
      };
    default:
      return state;
  }
};

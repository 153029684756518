import Axios from "axios";
import { clearItem, getItem } from "utils/tokenStore";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./ajax";
import getDomainURL from "utils/api";
import { setUserProfile } from "reducers/profile";

export const getProfile = () => (dispatch) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${getItem("EON_ERP_ACCESS_TOKEN")}`,
    },
    "Access-Control-Allow-Origin": "*",
  };
  Axios.get(`${getDomainURL()}/api/profile/me`)
    .then((response) => {
      dispatch(setUserProfile(response.data.data));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      if(error && error.response){
        if (error.response.status === 401) {
          alert(
            "Due to inactivity, your session has expired, please login again."
          );
          clearItem("EON_ERP_ACCESS_TOKEN");
          window.location.reload();
        }
        dispatch(ajaxCallError(error.response.data.message));
      } else {
        dispatch(ajaxCallError(error.message));
      }
    });
};

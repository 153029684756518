import React from "react"
import { FormGroup, Input } from "reactstrap"
import _ from "lodash"

import "./index.scss"

const CustomRadioButton = ({ 
  checked,
  label,
  onChange,
  formGroupClass,
  formGroupStyle,
  disabled,
  name,
  defaultChecked,
  containerClass,
  inputStyle
}) => {
  return (
    <FormGroup 
      style={{ ...formGroupStyle }} 
      className={formGroupClass || ""}
      check>
      <div className={`custom-form-radio-button ${containerClass || ""}`}>
        <Input
          type="radio"
          style={{ ... inputStyle }}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
        />
        {label}
      </div>
    </FormGroup>
  )
}

export default CustomRadioButton
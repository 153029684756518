import { HashRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { toast, ToastContainer } from "react-toastify"

import Router from "./router"
import configureStore from "./store/config"
import 'react-toastify/dist/ReactToastify.css';
import './stylesheets/base.scss'
import './stylesheets/font.css'

const store = configureStore()
const persistor = persistStore(store)

toast.configure()

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <Router />
        </HashRouter>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      </PersistGate>
    </Provider>
  )
}

export default App
import React from "react"

import "./index.scss"

const CustomCard = ({
  mode, //default, borderless, dashed
  disabled,
  containerClass,
  containerStyle,

  onClick,
  children,
}) => {
  return (
    <div
      style={containerStyle || {}}
      onClick={disabled ? () => {} : onClick}
      className={`custom-card custom-card-${mode || "default"} ${containerClass || ""}`}>
      {children}
    </div>
  )
}

export default CustomCard
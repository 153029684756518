import React from "react"

const CustomAvatar = ({
  containerStyle,
  imageStyle,
  imageUrl,
  imageAlt,
  imageWidth,
  imageHeight
}) => {
  return (
    <div style={{ display: "flex", padding: "3px 0px", ...containerStyle }}>
      <img
        src={imageUrl}
        alt={imageAlt || ""}
        style={{
          width: imageWidth,
          height: imageHeight,
          borderRadius: "50%",
          ...imageStyle
        }}
      />
    </div>
  )
}

export default CustomAvatar
import React, { useEffect } from "react"
import { TiTick } from "react-icons/ti"
import Loader from "react-loaders"

import CustomCard from "components/Card"
import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"
import CustomAlert from "components/Alert"

import EONLogo from 'assets/Images/eon-svg.svg'
import ResetPwHOC from "./actions"

const ResetPassword = props => {
  useEffect(() => {
    props.setPath([
      { label: "Loan Application" },
      { label: "Reset Password" },
    ])
  }, [])
  
  return (
    <>
      {/* <TopBar />
      <Breadcrumb pathData={[]} /> */}
      <div className="eon-main-inner" style={{ minHeight: '100vh', paddingTop: 100 }}>
        <div className="eon-login-title">
          <img src={ EONLogo } alt="eon-logo" />
          <span>Reset Password</span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          { props.loading
            ? <Loader type="ball-clip-rotate" />
            : (
              <CustomCard
                mode={"default"}
                containerStyle={{ padding: "24px", gap: "12px", width: "450px" }}>
                <span className="text-sm text-light-grey font-weight-light mb-0">Forgotten your password?</span>
                <span className="text-sm text-light-grey font-weight-light mb-2">We'll email you instructions to reset your password.</span>
                <FormInput
                  type="text"
                  context="email"
                  value={props.email}
                  label="Email"
                  placeholder={"Email"}
                  errors={props.errors}
                  onChangeData={(val) => props.onChangeResetPwHOC("email", val)}
                />
                <CustomAlert color={"success"} isOpen={props.showResetPwAlert}>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center text-sm font-weight-light">
                      <TiTick style={{ fontSize: "24px", marginRight: "8px" }}/>
                      Reset password email is sent. Please check your email.
                    </div>
                  </div>
                </CustomAlert>
                {!props.showResetPwAlert && (
                  <CustomButton
                    buttonClass={"full-width-button mt-2"}
                    size={"lg"}
                    color={"black"}
                    disabled={props.email.length < 1}
                    children={'Send Email'}
                    onClick={() => {
                      props.sendResetPwEmail({ email: props.email })
                      props.onChangeResetPwHOC("loading", true)
                    }}
                  />
                )}
                <CustomButton
                  size={"sm"}
                  color={"borderless"}
                  onClick={() => props.history.push('/login')}
                  children={"Back to Login Page"}
                />
              </CustomCard>
            )
          }
        </div>
      </div>
    </>
  )
}

export default ResetPwHOC(ResetPassword)